<template>
<div class="feedback"> 
    <h2>家长监护工程</h2>
    <div class="workers-process">
        <h3>申请流程</h3>
        <img src="../assets/images/process.png" alt="">
        <div class="application">
            <h5>监护人提出申请</h5>
            <p>下载表单：<a href="https://www.yingxiongbb.cn:8080/game/file/download.doc" style="color:blue;cursor: pointer;border-bottom:1px solid blue;" download>深圳卡昂未成年人用户家长监控服务申请书</a></p>
            <p>温馨提示，在邮箱申请时，要记得一起提供如下资料</p>
            <p>附件1：申请人的身份证或户口本（复印件）</p>
            <p>附件2：被申请人的身份证或户口本（复印件）</p>
            <p>附件3：申请人和被申请人的监护法律关系说明（复印件）</p>
        </div>
    </div>
    
</div>
</template>
<script>
import axios from "axios";
import base from "../api/base.js";
export default {
    methods:{
        down(){
            axios
          .get(base.api + "download")
          .then(res => {
            console.log(res)
          });
        }
    }
}
</script>

<style>
.feedback{
    width: 100%;
}
.feedback h2{
    text-align: center;
    width: 100%;
    height: 150px;
    line-height: 150px;
}
.workers-process{
    background: #fff;
    margin:0 auto;
    width: 1200px;
    padding: 20px;
    box-sizing: border-box;
}
.workers-process h3{
    padding: 20px;
    display: block;
    border-bottom: 2px solid #ccc;
}
.workers-process img{
    width: 1160px;
    display: block;
    margin:60px auto;
}
.application{
    margin:0 auto;
    padding: 20px;
    width: 1160px;
    height: 200px;
    border-top:1px dotted #ccc;
}
.application h5{
    font-size: 16px;
    line-height: 30px;
}
.application p{
    font-size: 14px;
    line-height: 30px;
}
</style>
